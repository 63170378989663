import React, { useEffect, useState } from 'react';
import "./Service.css";
import "../../../assets/css/main.css";
import service1 from "../../../assets/images/service1.png";
import service2 from "../../../assets/images/service2.png";
import service3 from "../../../assets/images/service3.png";
import service4 from "../../../assets/images/service4.png";
import { useTranslation } from 'react-i18next';

const Service = () => {
  const { t } = useTranslation()

  const [innerWidth, setInnerWidth] = useState()
  const [scrollY, setScrollY] = useState(window.scrollY)

  useEffect(() => {
    setInnerWidth(window.innerWidth)
  }, [])
  useEffect(() => {
    const scroll = () => {
      setScrollY(window.scrollY)
    }

    window.addEventListener('scroll', scroll)

    return () => {
      window.removeEventListener('scroll', scroll)
    }
  }, [])

    // MOBILE HEADER
  if (innerWidth && innerWidth < 480) {
    return(
      <div id="service">
    <h1 className="title serviceTitle">{t('translation:serviceTitle')}</h1>
    <div className="serviceGeneralContainerM">
      <div className="serviceImgContainerM"><img className="serviceImgM" src={service1} alt="servicePhoto" /></div>
      <div className="serviceContainerM">
            <h2 className="oneServiceTitleM">{t('translation:servicesCard1Title')}</h2>
            <p>{t('translation:servicesCard1P1')}</p>
            <p>{t('translation:servicesCard1P2')}</p>
            <p>{t('translation:servicesCard1P3')}</p>
      </div>
      <div className="serviceContainerM">
          <div className="serviceImgContainerM"><img className="serviceImgM" src={service2} alt="servicePhoto" /></div>
            <h2 className="oneServiceTitleM">{t('translation:servicesCard2Title')}</h2>
            <p>{t('translation:servicesCard2P1')}</p>
            <p>{t('translation:servicesCard2P2R1')}<br />
                {t('translation:servicesCard2P2R2')}<br />
                {t('translation:servicesCard2P2R3')}<br />
                {t('translation:servicesCard2P2R4')}<br />
                {t('translation:servicesCard2P2R5')}
                </p>
            <p>{t('translation:servicesCard2P3')}</p>
      </div>
      <div className="serviceContainerM">
          <div className="serviceImgContainerM"><img className="serviceImgM" src={service3} alt="servicePhoto" /></div>
            <h2 className="oneServiceTitleM">{t('translation:servicesCard3Title')}</h2>
            <p>{t('translation:servicesCard3P1')}</p>
            <p>{t('translation:servicesCard3P2')}</p>
            <p>{t('translation:servicesCard3P3')}</p>
      </div>
      <div className="serviceContainerM">
          <div className="serviceImgContainerM"><img className="serviceImgM" src={service4} alt="servicePhoto" /></div>
            <h2 className="oneServiceTitleM">{t('translation:servicesCard4Title')}</h2>
            <p>{t('translation:servicesCard4P1')}</p>
            <p>{t('translation:servicesCard4P2R1')}<br />
                {t('translation:servicesCard4P2R2')}<br />
                {t('translation:servicesCard4P2R3')}<br />
                {t('translation:servicesCard4P2R4')}<br />
                {t('translation:servicesCard4P2R5')}
            </p>
            <p>{t('translation:servicesCard4P3')}</p>
      </div>
    </div>
  </div>
    )

  }

  // WEB HEADER
  else{
  return (
    <div id="service">
    <h1 className="title serviceTitle">{t('translation:serviceTitle')}</h1>
    <div className="serviceContainer">
      <div className="serviceFlex1">
        <div><img className="service1Photo" src={service1} alt="servicePhoto" /></div>
        <div className="service1">
          <h2 className="oneServiceTitle">{t('translation:servicesCard1Title')}</h2>
          <p>{t('translation:servicesCard1P1')}</p>
          <p>{t('translation:servicesCard1P2')}</p>
          <p>{t('translation:servicesCard1P3')}</p>
        </div>
      </div>
    </div>
    <div className="serviceContainer">
      <div className="serviceFlex2">
        <div className="service2">
          <h2 className="oneServiceTitle">{t('translation:servicesCard2Title')}</h2>
          <p>{t('translation:servicesCard2P1')}</p>
          <p>{t('translation:servicesCard2P2R1')}<br />
              {t('translation:servicesCard2P2R2')}<br />
              {t('translation:servicesCard2P2R3')}<br />
              {t('translation:servicesCard2P2R4')}<br />
              {t('translation:servicesCard2P2R5')}
              </p>
          <p>{t('translation:servicesCard2P3')}</p>
        </div>
        <div><img className="service2Photo" src={service2} alt="servicePhoto" /></div>
      </div>
    </div>
    <div className="serviceContainer">
      <div className="serviceFlex1">
        <div><img className="service1Photo" src={service3} alt="servicePhoto" /></div>
        <div className="service1">
          <h2 className="oneServiceTitle">{t('translation:servicesCard3Title')}</h2>
          <p>{t('translation:servicesCard3P1')}</p>
          <p>{t('translation:servicesCard3P2')}</p>
          <p>{t('translation:servicesCard3P3')}</p>
        </div>
      </div>
    </div>
    <div className="serviceContainer">
      <div className="serviceFlex2">
        <div className="service2">
          <h2 className="oneServiceTitle">{t('translation:servicesCard4Title')}</h2>
          <p>{t('translation:servicesCard4P1')}</p>
          <p>{t('translation:servicesCard4P2R1')}<br />
              {t('translation:servicesCard4P2R2')}<br />
              {t('translation:servicesCard4P2R3')}<br />
              {t('translation:servicesCard4P2R4')}<br />
              {t('translation:servicesCard4P2R5')}
          </p>
          <p>{t('translation:servicesCard4P3')}</p>
        </div>
        <div><img className="service2Photo" src={service4} alt="servicePhoto" /></div>
      </div>
    </div>
  </div>
  )}
}

export default Service