import React, { useEffect, useState } from 'react';
import "../../../assets/css/main.css";
import firstTR from '../../../assets/images/firstTr.png';
import firstEN from '../../../assets/images/firstEn.png';
import firstDE from '../../../assets/images/firstDe.png';
import "./Home.css";

const Home = (props) => {
  const [language, setLanguage] = useState('')

  useEffect(() => {
    let lang = localStorage.getItem('multilang')
    if (!lang) lang = 'tr'
    setLanguage(lang)
  }, [])
console.log(props);

  return (
    <div className="home-photo-container" id="home">
      <img src={language === "tr" ? firstTR : language === "en" ? firstEN : firstDE} alt="mainImg" className="home-photo" />
    </div>
  )
}

export default Home