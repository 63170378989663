import React from 'react';
import "./Processes.css";
import "../../../assets/css/main.css";
import processes1 from "../../../assets/images/processes1.png";
import processes2 from "../../../assets/images/processes2.png";
import processes3 from "../../../assets/images/processes3.png";
import processes4 from "../../../assets/images/processes4.png";
import processes5 from "../../../assets/images/processes5.png";
import processes6 from "../../../assets/images/processes6.png";
import processes7 from "../../../assets/images/processes7.png";
import processes8 from "../../../assets/images/processes8.png";
import { useTranslation } from 'react-i18next';

const Processes = () => {
  const { t } = useTranslation()

  return (
    <div id="processes">
      <h1 className="title processesTitle">{t('translation:processesTitle')}</h1>
      <div className="processes">
        <div className="process">
          <img className="processImg" src={processes1} alt="processes1" />
          <h2 className="processTitle">{t('translation:processTitle1')}</h2>
          <p className="processP">{t('translation:processP1')}</p>
        </div>
        <div className="process">
          <img className="processImg" src={processes2} alt="processes2" />
          <h2 className="processTitle">{t('translation:processTitle2')}</h2>
          <p className="processP">{t('translation:processP2')}</p>
        </div>
        <div className="process">
          <img className="processImg" src={processes3} alt="processes3" />
          <h2 className="processTitle">{t('translation:processTitle3')}</h2>
          <p className="processP">{t('translation:processP3')}</p>
        </div>
        <div className="process">
          <img className="processImg" src={processes4} alt="processes4" />
          <h2 className="processTitle">{t('translation:processTitle4')}</h2>
          <p className="processP">{t('translation:processP4')}</p>
        </div>
        <div className="process">
          <img className="processImg" src={processes5} alt="processes5" />
          <h2 className="processTitle">{t('translation:processTitle5')}</h2>
          <p className="processP">{t('translation:processP5')}</p>
        </div>
        <div className="process">
          <img className="processImg" src={processes6} alt="processes6" />
          <h2 className="processTitle">{t('translation:processTitle6')}</h2>
          <p className="processP">{t('translation:processP6')}</p>
        </div>
        <div className="process">
          <img className="processImg" src={processes7} alt="processes7" />
          <h2 className="processTitle">{t('translation:processTitle7')}</h2>
          <p className="processP">{t('translation:processP7')}</p>
        </div>
        <div className="process">
          <img className="processImg" src={processes8} alt="processes8" />
          <h2 className="processTitle">{t('translation:processTitle8')}</h2>
          <p className="processP">{t('translation:processP8')}</p>
        </div>
        <div className="offer">
          <a href="#contact"><button className="offerButton">{t('translation:processOffer')}</button></a>
          
      </div>
      </div>

    </div>
  )
}

export default Processes