import React from 'react';
import "../../../assets/css/main.css";
import "./AboutUs.css"
import aboutUsImg from "../../../assets/images/aboutUsImg.png";
import { useTranslation } from 'react-i18next';

const AboutUs = () => {
  const { t } = useTranslation()

  return (
    <div id="aboutUs">
      <h1 className="title">{t('translation:aboutUsTitle')}</h1>
      <div className="aboutUsImgContainer">
        <img className="aboutUsImg" src={aboutUsImg} alt="aboutUsImg" />
      </div>
      <div className="aboutUsContainer">
        <p>{t('translation:aboutUsP1')}<br /><br />
{t('translation:aboutUsP2')}<br /><br />
{t('translation:aboutUsP3')}<br /><br />
{t('translation:aboutUsP4')}<br /><br />
{t('translation:aboutUsP5')}<br /><br />
{t('translation:aboutUsP6')}<br /><br />
{t('translation:aboutUsP7')}<br /><br />
{t('translation:aboutUsP8')}</p>
      </div>
    </div>
  )
}

export default AboutUs