import React from 'react';
import "../../../assets/css/main.css";
import contact from "../../../assets/images/contact.png";
import { useTranslation } from 'react-i18next';
import "./Contact.css"

const Contact = () => {
  const { t } = useTranslation()

  return (
    <div id="contact">
      <h1 className="title contactTitle">{t('translation:contactTitle')}</h1>
      <div className="contactFlex">
        <div className="description">
          <img className="contactImg" src={contact} alt="contact" />
          <div className="pContainer">
            <h2>{t('translation:descriptionTitle')}</h2>
            <p>{t('translation:descriptionP')}</p>
          </div>
        </div>
        <div className="form">
          <div className="formContainer">
            <form action="">
              <p>{t('translation:formP1')}</p>
              <input type="text" name="" id="" />
              <p>{t('translation:formP2')}</p>
              <input type="email" name="" id="" />
              <p>{t('translation:formP3')}</p>
              <input type="phone" name="" id="" />
              <p>{t('translation:formP4')}</p>
              <textarea name="" id=""></textarea>
              <button type="submit" className="submit">{t('translation:submit')}</button>
            </form>
        </div>
        </div>

      </div>
    </div>
  )
}

export default Contact