import React from 'react';
import "../../../assets/css/main.css";
import "./Projects.css"
import duyuroom from "../../../assets/images/projects/duyuroom.png";
import byHorses from "../../../assets/images/projects/byHorses.png";
import clane from "../../../assets/images/projects/clane.png";
import hds from "../../../assets/images/projects/hds.png";
import hizliHesap from "../../../assets/images/projects/hizliHesap.png";
import kfcGida from "../../../assets/images/projects/kfcGida.png";
import promodex from "../../../assets/images/projects/promodex.png";
import proSurus from "../../../assets/images/projects/proSurus.png";
import yesilay from "../../../assets/images/projects/yesilay.png";
import { useTranslation } from 'react-i18next';

const Projects = () => {
  const { t } = useTranslation()

  return (
    <div id="projects">
      <h1 className="title projectsTitle">{t('translation:projectsTitle')}</h1>
      <div className="projects">
        <div className="project">
          <div className="projectFlex">
            <img className="duyuroomImg" src={duyuroom} alt="duyuroom" />
            <h2 className="projectTitle">DUYUROOM</h2>
          </div>
          <p className="projectP">{t('translation:project1P1')}</p>
        </div>
        <div className="project">
          <div className="projectFlex">
            <img className="duyuroomImg" src={yesilay} alt="yesilay" />
            <h2 className="projectTitle">YEŞİLAY LMS</h2>
          </div>
          <p className="projectP">{t('translation:project2P1')}</p>
        </div>
        <div className="project">
          <div className="projectFlex">
            <img className="duyuroomImg" src={hizliHesap} alt="hizliHesap" />
            <h2 className="projectTitle">HIZLI HESAP</h2>
          </div>
          <p className="projectP">{t('translation:project3P1')}</p>
        </div>
        <div className="project">
          <div className="projectFlex">
            <img className="duyuroomImg" src={promodex} alt="promodex" />
            <h2 className="projectTitle">PROMODEX</h2>
          </div>
          <p className="projectP">{t('translation:project4P1')}</p>
        </div>
        <div className="project">
          <div className="projectFlex">
            <img className="duyuroomImg" src={clane} alt="clane" />
            <h2 className="projectTitle">CLANE</h2>
          </div>
          <p className="projectP">{t('translation:project5P1')}</p>
        </div>
        <div className="project">
          <div className="projectFlex">
            <img className="duyuroomImg" src={byHorses} alt="byHorses" />
            <h2 className="projectTitle">BYHORSES</h2>
          </div>
          <p className="projectP">{t('translation:project6P1')}</p>
        </div>
        <div className="project">
          <div className="projectFlex">
            <img className="duyuroomImg" src={kfcGida} alt="kfcGida" />
            <h2 className="projectTitle">KFC GIDA</h2>
          </div>
          <p className="projectP">{t('translation:project7P1')}</p>
        </div>
        <div className="project">
          <div className="projectFlex">
            <img className="duyuroomImg" src={hds} alt="hds" />
            <h2 className="projectTitle">HOTEL DIRECT SALE</h2>
          </div>
          <p className="projectP">{t('translation:project8P1')}</p>
        </div>
        <div className="project">
          <div className="projectFlex">
            <img className="duyuroomImg" src={proSurus} alt="proSurus" />
            <h2 className="projectTitle">PRO SÜRÜŞ</h2>
          </div>
          <p className="projectP">{t('translation:project9P1')}</p>
        </div>
      </div>
    </div>
  )
}

export default Projects