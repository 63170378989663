import React from 'react';
import "../../../assets/css/main.css";
import "./OurTeam.css";
import ourTeamImg1 from "../../../assets/images/ourTeamImg1.png";
import ourTeamImg2 from "../../../assets/images/ourTeamImg2.png";
import ourTeamImg3 from "../../../assets/images/ourTeamImg3.png";
import ourTeamImg4 from "../../../assets/images/ourTeamImg4.png";
import ourTeamImg5 from "../../../assets/images/ourTeamImg5.png";
import ourTeamImg6 from "../../../assets/images/ourTeamImg6.png";
import ourTeamImg7 from "../../../assets/images/ourTeamImg7.png";
import ourTeamImg8 from "../../../assets/images/ourTeamImg8.png";
import { useTranslation } from 'react-i18next';


const OurTeam = () => {
  const { t } = useTranslation()

  return (
    <div id="ourTeam">
      <h1 className="title">{t('translation:ourTeamTitle')}</h1>
      <div className="ourTeamGeneralContainer">
        <div className="ourTeamImgContainer">
          <img className="ourTeamImg" src={ourTeamImg1} alt="ourTeamImg1" />
        <div className="ourTeamContainer">
          <center className="teamName">Hakan</center>
          <center>{t('translation:techDirector')}</center>
        </div>
        </div>
        <div className="ourTeamImgContainer">
          <img className="ourTeamImg" src={ourTeamImg2} alt="ourTeamImg2" />
        <div className="ourTeamContainer">
          <center className="teamName">Melisa</center>
          <center>{t('translation:creativeDirector')}</center>
        </div>
        </div>
        <div className="ourTeamImgContainer">
          <img className="ourTeamImg" src={ourTeamImg3} alt="ourTeamImg3" />
        <div className="ourTeamContainer">
          <center className="teamName">Salih</center>
          <center>{t('translation:developerPlus')}</center>
        </div>
        </div>
        <div className="ourTeamImgContainer">
          <img className="ourTeamImg" src={ourTeamImg4} alt="ourTeamImg4" />
        <div className="ourTeamContainer">
          <center className="teamName">Gökhan</center>
          <center>{t('translation:blockchain')}</center>
        </div>
        </div>
        <div className="ourTeamImgContainer">
          <img className="ourTeamImg" src={ourTeamImg5} alt="ourTeamImg5" />
        <div className="ourTeamContainer">
          <center className="teamName">Durmuş</center>
          <center>{t('translation:developer')}</center>
        </div>
        </div>
        <div className="ourTeamImgContainer">
          <img className="ourTeamImg" src={ourTeamImg6} alt="ourTeamImg6" />
        <div className="ourTeamContainer">
          <center className="teamName">İlyas</center>
          <center>{t('translation:developer')}</center>
        </div>
        </div>
        <div className="ourTeamImgContainer">
          <img className="ourTeamImg" src={ourTeamImg7} alt="ourTeamImg7" />
        <div className="ourTeamContainer">
          <center className="teamName">Sezer</center>
          <center>{t('translation:developer')}</center>
        </div>
        </div>
        <div className="ourTeamImgContainer">
          <img className="ourTeamImg" src={ourTeamImg8} alt="ourTeamImg8" />
        <div className="ourTeamContainer">
          <center className="teamName">Hamza</center>
          <center>{t('translation:developer')}</center>
        </div>
        </div>
      </div>
    </div>
  )
}

export default OurTeam