import React from 'react';
import "./Technologies.css";
import "../../../assets/css/main.css";
import tech1 from "../../../assets/images/technologies/tech1.png";
import tech2 from "../../../assets/images/technologies/tech2.png";
import tech3 from "../../../assets/images/technologies/tech3.png";
import tech4 from "../../../assets/images/technologies/tech4.png";
import tech5 from "../../../assets/images/technologies/tech5.png";
import tech6 from "../../../assets/images/technologies/tech6.png";
import tech7 from "../../../assets/images/technologies/tech7.png";
import tech8 from "../../../assets/images/technologies/tech8.png";
import tech9 from "../../../assets/images/technologies/tech9.png";
import tech10 from "../../../assets/images/technologies/tech10.png";
import tech11 from "../../../assets/images/technologies/tech11.png";
import tech12 from "../../../assets/images/technologies/tech12.png";
import tech13 from "../../../assets/images/technologies/tech13.png";
import tech14 from "../../../assets/images/technologies/tech14.png";
import tech15 from "../../../assets/images/technologies/tech15.png";
import tech16 from "../../../assets/images/technologies/tech16.png";
import tech17 from "../../../assets/images/technologies/tech17.png";
import tech18 from "../../../assets/images/technologies/tech18.png";
import tech19 from "../../../assets/images/technologies/tech19.png";
import tech20 from "../../../assets/images/technologies/tech20.png";
import tech21 from "../../../assets/images/technologies/tech21.png";
import tech22 from "../../../assets/images/technologies/tech22.png";
import tech23 from "../../../assets/images/technologies/tech23.png";
import tech24 from "../../../assets/images/technologies/tech24.png";
import tech25 from "../../../assets/images/technologies/tech25.png";
import tech26 from "../../../assets/images/technologies/tech26.png";
import tech27 from "../../../assets/images/technologies/tech27.png";
import tech28 from "../../../assets/images/technologies/tech28.png";
import tech29 from "../../../assets/images/technologies/tech29.png";
import tech30 from "../../../assets/images/technologies/tech30.png";
import tech31 from "../../../assets/images/technologies/tech31.png";
import tech32 from "../../../assets/images/technologies/tech32.png";
import { useTranslation } from 'react-i18next';

const Technologies = () => {
  const { t } = useTranslation()

  return (
    <div id="technologies">
      <h1 className="title">{t('translation:technologiesTitle')}</h1>
      <div className="techContainer techContainer1"></div>
      <div className="container1">
        <div><img className="techImg" src={tech1} alt="" /></div>
        <div><img className="techImg" src={tech2} alt="" /></div>
        <div><img className="techImg" src={tech3} alt="" /></div>
        <div><img className="techImg" src={tech4} alt="" /></div>
        <div><img className="techImg" src={tech5} alt="" /></div>
        <div><img className="techImg" src={tech6} alt="" /></div>
        <div><img className="techImg" src={tech7} alt="" /></div>
        <div><img className="techImg" src={tech8} alt="" /></div>
        <div><img className="techImg" src={tech9} alt="" /></div>
        <div><img className="techImg" src={tech10} alt="" /></div>
        <div><img className="techImg" src={tech11} alt="" /></div>
        <div><img className="techImg" src={tech12} alt="" /></div>
        <div><img className="techImg" src={tech13} alt="" /></div>
        <div><img className="techImg" src={tech14} alt="" /></div>
        <div><img className="techImg" src={tech15} alt="" /></div>
        <div><img className="techImg" src={tech16} alt="" /></div>
        <div><img className="techImg" src={tech1} alt="" /></div>
        <div><img className="techImg" src={tech2} alt="" /></div>
        <div><img className="techImg" src={tech3} alt="" /></div>
        <div><img className="techImg" src={tech4} alt="" /></div>
        <div><img className="techImg" src={tech5} alt="" /></div>
      </div>
      <div className="techContainer techContainer2"></div>
      <div className="container2">
        <div><img className="techImg" src={tech17} alt="" /></div>
        <div><img className="techImg" src={tech18} alt="" /></div>
        <div><img className="techImg" src={tech19} alt="" /></div>
        <div><img className="techImg" src={tech20} alt="" /></div>
        <div><img className="techImg" src={tech21} alt="" /></div>
        <div><img className="techImg" src={tech22} alt="" /></div>
        <div><img className="techImg" src={tech23} alt="" /></div>
        <div><img className="techImg" src={tech24} alt="" /></div>
        <div><img className="techImg" src={tech25} alt="" /></div>
        <div><img className="techImg" src={tech26} alt="" /></div>
        <div><img className="techImg" src={tech27} alt="" /></div>
        <div><img className="techImg" src={tech28} alt="" /></div>
        <div><img className="techImg" src={tech29} alt="" /></div>
        <div><img className="techImg" src={tech30} alt="" /></div>
        <div><img className="techImg" src={tech31} alt="" /></div>
        <div><img className="techImg" src={tech32} alt="" /></div>
        <div><img className="techImg" src={tech17} alt="" /></div>
        <div><img className="techImg" src={tech18} alt="" /></div>
        <div><img className="techImg" src={tech19} alt="" /></div>
        <div><img className="techImg" src={tech20} alt="" /></div>
        <div><img className="techImg" src={tech21} alt="" /></div>
      </div>
      <p className="technologiesP">{t('translation:technologiesP1')}</p>
      <p className="technologiesP">{t('translation:technologiesP2')}</p>
    </div>
  )
}

export default Technologies