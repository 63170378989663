import React from "react";
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./routes/ScrollToTop";
import Header from "./components/header/Header";
import Service from "./components/main/service/Service";
import Technologies from "./components/main/technologies/Technologies";
import Projects from "./components/main/projects/Projects";
import Home from "./components/main/home/Home";
import AboutUs from "./components/main/aboutUs/AboutUs";
import OurTeam from "./components/main/ourTeam/OurTeam";
import Contact from "./components/main/contact/Contact";
import Body from "./pages/body/Body";



const App = () => {

  return (
    <Provider store={configureStore()}>
      <BrowserRouter>
          <ScrollToTop>
            <Body/>
            <Routes>
              <Route exact path="/" component={Header} />
              <Route exact path="/service" component={Service} />
              <Route exact path="/technologies" component={Technologies} />
              <Route exact path="/projects" component={Projects} />
              <Route exact path="/home" component={Home} />
              <Route exact path="/about-us" component={AboutUs} />
              <Route exact path="/ourTeam" component={OurTeam} />
              <Route exact path="/contact" component={Contact} />
            </Routes>
          </ScrollToTop>
      </BrowserRouter>
    </Provider>
  );
}
export default App;
