import React, { useEffect, useState } from 'react'
import i18n from '../languages/i18n'

const SelectLanguage = (props) => {
  const [language, setLanguage] = useState('')

  useEffect(() => {
    let lang = localStorage.getItem('multilang')
    if (!lang) lang = 'tr'
    setLanguage(lang.toUpperCase())
  }, [])


  const changeLanguage = () => {
    let lang;
    if (language === "TR") { lang = "en" }
    else if (language === "EN") { lang = "de" }
    else { lang = "tr"}
    console.log('LANG: ', lang)
    i18n.changeLanguage(lang)
    localStorage.setItem('multilang', lang)
    setLanguage(lang.toUpperCase())
    window.location.reload()
  }

  return (
    <span style={props.style || undefined} id="lang-btn" className='lang-btn' onClick={changeLanguage}>{language}</span>
  )
}

export default SelectLanguage