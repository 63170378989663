import React from "react";
import { useTranslation } from "react-i18next";
import "./Footer.css";
import iso1 from "../../assets/images/iso1.png";
import iso2 from "../../assets/images/iso2.png";

  
const Footer = () => {
     const { t } = useTranslation()
  return (
    <div className="footer">
      <div className="footerFlex" style={{width: "100%"}}>
        <div className="footerFlex">
          <div className="features">
            <h2 className="footerTitle" >{t('translation:footerFeatures')}</h2>
            <a className="footer-link" href="#service">{t('translation:footerServices')}</a>
            <a className="footer-link" href="#processes">{t('translation:footerProcesses')}</a>
            <a className="footer-link" href="#technologies">{t('translation:footerTechnologies')}</a>
            <a className="footer-link" href="#projects">{t('translation:footerProjects')}</a>
          </div>
          <div className="platform">
            <h2 className="footerTitle" >{t('translation:footerPlatform')}</h2>
            <a className="footer-link" href="#aboutUs">{t('translation:footerAboutUs')}</a>
            <a className="footer-link" href="#ourTeam">{t('translation:footerOurTeam')}</a>
            <a className="footer-link" href="#contactUs">{t('translation:footerContactUs')}</a>
          </div>
        </div>
        <div className="footerFlex">
          <div style={{marginTop:20, marginRight:30}}><img style={{width: "130px"}} src={iso1} alt="" /></div>
          <div  style={{marginTop:20}}><img style={{width: "130px"}} src={iso2} alt="" /></div>
        </div>
      </div>
    </div>
  );
};
export default Footer;