import React, { useEffect, useState } from 'react'
import { Col, Dropdown, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import logo from "../../assets/images/logo.png"
import icon from "../../assets/images/icon.png"
import SelectLanguage from '../../pages/SelectLanguage'
import  '../../assets/css/main.css'


const Header = () => {
  const { t } = useTranslation()

  const [innerWidth, setInnerWidth] = useState()
  const [scrollY, setScrollY] = useState(window.scrollY)

  useEffect(() => {
    setInnerWidth(window.innerWidth)
  }, [])
  useEffect(() => {
    const scroll = () => {
      setScrollY(window.scrollY)
    }
    console.log(scrollY)

    window.addEventListener('scroll', scroll)

    return () => {
      window.removeEventListener('scroll', scroll)
    }
  }, [])

  // MOBILE HEADER
  if (innerWidth && innerWidth < 768) {
    return (
      <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        paddingTop: 20,
        paddingBottom: 20,
        zIndex: 10,
        width: "100%",
        backgroundColor: scrollY < 200 ? "transparent" : "rgba(255,255,255, 1)", transition: "all 0.5s"
      }}
      >
        <Row>
          <Col xs={{ span: 7, offset: 1 }}>
            <a className="header-link" href="#home">
              <img src={logo} alt={"img"} style={{ width: "60%",marginTop: 8 }} />
            </a>
          </Col>
          <Col xs={3}>
            <Dropdown>
              <Dropdown.Toggle className='mobile-header-btn' >
              <img src={icon} alt={"img"} style={{ width: "10%",height:"10%"}} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className="header-link" href="#service">{t('translation:service')}</Dropdown.Item>
                <Dropdown.Item className="header-link" href="#technologies">{t('translation:technologies')}</Dropdown.Item>
                <Dropdown.Item className="header-link" href="#projects">{t('translation:projects')}</Dropdown.Item>
                <Dropdown.Item className="header-link" href="#home">{t('translation:home')}</Dropdown.Item>
                <Dropdown.Item className="header-link" href="#aboutUs">{t('translation:aboutUs')}</Dropdown.Item>
                <Dropdown.Item className="header-link" href="#products">{t('translation:products')}</Dropdown.Item>
                <Dropdown.Item className="header-link" onClick={() => {
                  document.getElementById("lang-btn").click()
                }}>{t('translation:languageReverse')}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <SelectLanguage style={{display: "none"}} />
          </Col>
        </Row>
      </div>
    )
  }
  // WEB HEADER
  else{
  return (
    <div style={{
      position: "fixed",
      top: 0,
      left: 0,
      // paddingBottom: 20,
      zIndex: 10,
      width: "100%",
      background: scrollY < 200 ? "transparent" : "rgba(255,255,255, 1)", transition: "all 0.5s"
    }}>
      <Row className='header-container navbar'>
        <Col xs={{ span: 4, offset: 2 }}>
          <Row align='start' className='header-container'>
            <Col>
              <a className="header-link" href="#service">{t('translation:service')}</a>
            </Col>
            <Col>
              <a className="header-link" href="#technologies">{t('translation:technologies')}</a>
            </Col>
            <Col>
              <a className="header-link" href="#projects">{t('translation:projects')}</a>
            </Col>
            <Col>
              <a className="header-link" href="#processes">{t('translation:processes')}</a>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 4, offset: 1 }}>
          <a className="header-link" href="#home">
            <img src={logo} alt={"img"} style={{ width: "60%" }} />
          </a>
        </Col>
        <Col xs={{ span: 4, offset: 2 }}>
          <Row align='end' className='header-container'>
            <Col>
              <a className="header-link" href="#aboutUs">{t('translation:aboutUs')}</a>
            </Col>
            <Col>
              <a className="header-link" href="#ourTeam">{t('translation:ourTeam')}</a>
            </Col>
            <Col>
              <a className="header-link" href="#contact">{t('translation:contact')}</a>
            </Col>
            <Col>
            <SelectLanguage />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )}
}

export default Header