import React from 'react';
import Service from "../../components/main/service/Service";
import Technologies from "../../components/main/technologies/Technologies";
import Projects from "../../components/main/projects/Projects";
import Home from "../../components/main/home/Home";
import AboutUs from "../../components/main/aboutUs/AboutUs";
import OurTeam from "../../components/main/ourTeam/OurTeam";
import Contact from "../../components/main/contact/Contact";
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import Processes from '../../components/main/processes/Processes';

const Body = () => {
  return (
    <>
    <Header/>
    <Home/>
    <Service/>
    <Processes/>
    <Technologies/>
    <AboutUs/>
    <OurTeam/>
    <Projects/>
    <Contact/>
    <Footer/>
    </>
  )
}

export default Body